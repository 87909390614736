html, body, #root, #root > div {
  //height: 100%
}

.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  border: 0 !important;
}

.offcanvas-header {
  background-color: #0f223b;
}

.offcanvas-title {
  color: white;
}

.offcanvas {
  @media (max-width: 768px) {
    width: 80% !important;
  }
  @media (min-width: 768px) {
    width: 75% !important;
  }
  @media (min-width: 992px) {
    width: 70% !important;
  }
  @media (min-width: 1400px) {
    width: 50% !important;
  }
}
